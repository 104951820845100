<template>
  <v-dialog
    v-if="EditClinicDialog"
    :value="EditClinicDialog"
    width="600"
    persistent
  >
    <v-card v-click-outside="handleClickOutSide">
      <v-card-title class="headline mb-3 grey lighten-4">
        <h5 class="text-right pr-1">تعديل معلومات العيادة</h5>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="text-center mb-2">
        <v-alert dense outlined type="error" v-if="errorAlrt">
          حدث خطاء فشل في العملية
        </v-alert>
        <v-alert dense outlined type="success" v-if="successAlrt">
          عملية التحديث تمت بنجاح
        </v-alert>
        <v-form ref="edit_clinic_form" lazy-validation>
          <v-col cols="12" md="8" lg="6" class="text-center mx-auto">
            <v-avatar size="65">
              <img :src="clinicData.clinic_logo" alt="clinic logo" />
            </v-avatar>
            <v-file-input
              v-model="clinicLogo"
              accept="image/png, image/jpeg, image/jpg"
              placeholder="اختيار صورة للعيادة"
              prepend-icon="mdi-camera"
              label="صورة جديدة"
            ></v-file-input>
          </v-col>
          <v-text-field
            label="اسم العيادة"
            :rules="requiredRule"
            v-model="clinicData.clinic_name"
          >
          </v-text-field>
          <v-text-field
            label="عنوان العيادة"
            v-model="clinicData.clinic_address"
            :rules="requiredRule"
          >
          </v-text-field>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                type="tel"
                label="رقم هاتف العيادة"
                v-model="clinicData.mobile_line1"
                :rules="mobileRule"
                maxlength="10"
                minlength="10"
                suffix="964+"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="3">
              <v-autocomplete
                v-model="chosenDay"
                :items="days"
                item-text="val"
                item-value="val"
                label="اليوم"
                placeholder="اختر وقت العمل"
                required
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3">
              <v-dialog
                ref="fromDialog"
                v-model="fromDialog"
                :return-value.sync="chosenTimeFrom"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="chosenTimeFrom"
                    label="من توقيت"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="fromDialog"
                  v-model="chosenTimeFrom"
                  full-width
                >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.fromDialog.save(chosenTimeFrom)"
                  >
                    حفظ
                  </v-btn>
                  <v-btn text color="red" @click="fromDialog = false">
                    الغاء
                  </v-btn>

                  <v-spacer></v-spacer>
                </v-time-picker>
              </v-dialog>
            </v-col>

            <v-col cols="12" sm="3">
              <v-dialog
                ref="toDialog"
                v-model="toDialog"
                :return-value.sync="chosenTimeTo"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="chosenTimeTo"
                    label="الى توقيت"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="toDialog"
                  v-model="chosenTimeTo"
                  full-width
                >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.toDialog.save(chosenTimeTo)"
                  >
                    حفظ
                  </v-btn>
                  <v-btn text color="red" @click="toDialog = false">
                    الغاء
                  </v-btn>

                  <v-spacer></v-spacer>
                </v-time-picker>
              </v-dialog>
            </v-col>
            <v-col cols="2" class="mt-2 text-center">
              <v-btn
                @click="addWorkSchedule"
                fab
                small
                color="green white--text"
              >
                <v-icon dark> mdi-plus </v-icon></v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-list
                style="max-height: 250px"
                class="overflow-y-auto text-right"
                two-line
                subheader
              >
                <v-subheader>التوقيتات</v-subheader>

                <v-list-item
                  v-for="(time, index) in clinicData.work_time"
                  :key="time.index"
                >
                  <v-list-item-icon>
                    <v-icon
                      @click="removeWorkScheduleFromList(index)"
                      color="red"
                      style="cursor: pointer"
                      >mdi-delete</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ time.day }} </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ time.from }} | {{ time.to }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="indigo white--text"
          :loading="loadingSpinner"
          :disabled="loadingSpinner"
          @click="updateClinic"
          large
        >
          تحديث
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>

        <v-btn color="primary" text @click="closeDialog"> اغلاق </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <v-snackbar v-model="snackbar">
      الرجاء اختيار اليوم والتوقيت

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          اغلاق
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
  import { mapActions } from "vuex";

  export default {
    props: ["EditClinicDialog", "clinicData"],
    data() {
      return {
        requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
        mobileRule: [
          (v) =>
            /^7[3-9][0-9]{8}/.test(v) ||
            "اكتب رقم الهاتف بصورة صحيحة وبدون صفر في البداية",
        ],
        loadingSpinner: false,
        errorAlrt: false,
        successAlrt: false,

        chosenDay: "",
        chosenTimeFrom: null,
        chosenTimeTo: null,
        fromDialog: false,
        toDialog: false,
        date: new Date().toISOString().substr(0, 10),
        days: [
          { id: 1, val: "الجمعة" },
          { id: 2, val: "السبت" },
          { id: 3, val: "الاحد" },
          { id: 4, val: "الاثنين" },
          { id: 5, val: "الثلاثاء" },
          { id: 6, val: "الاربعاء" },
          { id: 7, val: "الخميس" },
        ],
        snackbar: false,
        clinicLogo: null,
        ClinicForm: new FormData(),
      };
    },

    methods: {
      ...mapActions(["updateClinicData"]),
      closeDialog() {
        this.$emit("editClinicDialogClose", false);
      },
      handleClickOutSide(e) {
        if (e.target.className == "v-overlay__scrim") this.closeDialog();
      },
      removeWorkScheduleFromList(i) {
        this.clinicData.work_time.splice(i, 1);
      },
      addWorkSchedule() {
        if (!this.chosenDay || !this.chosenTimeFrom || !this.chosenTimeTo) {
          this.snackbar = true;
          return;
        }

        var schedule = {
          day: this.chosenDay,
          from: this.chosenTimeFrom,
          to: this.chosenTimeTo,
        };
        this.clinicData.work_time.push(schedule);
        console.log(schedule);
      },
      updateClinic() {
        if (!this.$refs.edit_clinic_form.validate()) return;
        this.loadingSpinner = true;
        this.ClinicForm.append("name", this.clinicData.clinic_name);
        this.ClinicForm.append("address", this.clinicData.clinic_address);
        this.ClinicForm.append("mobile1", this.clinicData.mobile_line1);
        this.ClinicForm.append(
          "workTime",
          JSON.stringify(this.clinicData.work_time)
        );
        this.ClinicForm.append("id", this.clinicData.id);
        if (this.clinicLogo)
          this.ClinicForm.append("clinicLogo", this.clinicLogo);
        this.ClinicForm.append("_method", "PUT");

        this.updateClinicData(this.ClinicForm)
          .then((msg) => {
            if (msg == "success") {
              this.ClinicForm = new FormData();
              this.loadingSpinner = false;
              this.successAlrt = true;
              this.errorAlrt = false;
              setTimeout(() => {
                this.successAlrt = false;
                this.errorAlrt = false;
                this.$refs.edit_clinic_form.reset();
                this.closeDialog();
              }, 3000);
            }
          })
          .catch(() => {
            this.errorAlrt = true;
            this.loadingSpinner = false;
          });
      },
    },
  };
</script>

<style scoped lang="scss">
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }

  @-moz-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
</style>
