import axios from "@/axios";
const state = {
  charts: {},
};

const getters = {
  getCharts: (state) => state.charts,
};
const actions = {
  async fetchCharts({ commit }) {
    const response = await axios.get("/clinic/charts");
    commit("SET_CHARTS", response.data);
  },
};
const mutations = {
  SET_CHARTS: (state, data) => (state.charts = data),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
