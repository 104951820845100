<template>
  <div class="charts">
    <v-container fluid class="px-1 px-sm-1 px-md-2 px-lg-3">
      <div class="title mb-1" style="font-family: cairo !important">
        الاحصائيات
      </div>

      <!-- Reservations by Month -->
      <v-card class="mb-3 elevation-2">
        <v-card-title
          class="text-center"
          style="font-family: cairo; font-size: 18px; font-weight: bold"
        >
          عدد الحجوزات لكل شهر {{ currentYear }}
        </v-card-title>
        <v-card-text>
          <apexchart
            type="bar"
            :options="reservationChartOptions"
            :series="reservationChartSeries"
          />
        </v-card-text>
      </v-card>

      <!-- Reservations by Status -->
      <v-card class="mb-3 elevation-2">
        <v-card-title
          class="text-center"
          style="font-family: cairo; font-size: 18px; font-weight: bold"
        >
          الحجوزات حسب الحالة
        </v-card-title>
        <v-card-text>
          <apexchart
            type="pie"
            :options="statusChartOptions"
            :series="statusChartSeries"
          />
        </v-card-text>
      </v-card>

      <!-- Patient Demographics -->
      <v-card class="mb-3 elevation-2">
        <v-card-title
          class="text-center"
          style="font-family: cairo; font-size: 18px; font-weight: bold"
        >
          التوزيع حسب الجنس
        </v-card-title>
        <v-card-text>
          <apexchart
            type="pie"
            :options="genderChartOptions"
            :series="genderChartSeries"
          />
        </v-card-text>
      </v-card>

      <!-- New Patients by Month -->
      <v-card class="mb-3 elevation-2">
        <v-card-title
          class="text-center"
          style="font-family: cairo; font-size: 18px; font-weight: bold"
        >
          عدد المرضى الجدد لكل شهر {{ currentYear }}
        </v-card-title>
        <v-card-text>
          <apexchart
            type="line"
            :options="newPatientChartOptions"
            :series="newPatientChartSeries"
          />
        </v-card-text>
      </v-card>

      <v-card class="mb-3 elevation-2">
        <v-card-title
          class="text-center"
          style="font-family: cairo; font-size: 18px; font-weight: bold"
        >
          <div class="">الإيرادات الشهرية {{ currentYear }}</div>
        </v-card-title>
        <div class="px-4">الاجمالي: {{ totalRevenueSeries }}</div>

        <v-card-text>
          <apexchart
            type="line"
            :options="monthlyRevenueOptions"
            :series="monthlyRevenueSeries"
          />
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import VueApexCharts from "vue-apexcharts";
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: "ChartsPage",
    components: {
      apexchart: VueApexCharts,
    },
    data() {
      return {
        currentYear: new Date().getFullYear(), // Set the current year
      };
    },
    created() {
      this.fetchCharts();
    },
    computed: {
      ...mapGetters(["getCharts"]),
      totalRevenueSeries() {
        return [this.getCharts?.totalRevenue || 0];
      },

      monthlyRevenueSeries() {
        return [
          {
            name: "الإيرادات",
            data:
              this.getCharts?.monthlyRevenue?.map((item) => item.revenue) || [],
          },
        ];
      },
      monthlyRevenueOptions() {
        return {
          chart: { type: "bar", height: 350 },
          xaxis: {
            categories:
              this.getCharts?.monthlyRevenue?.map(
                (item) => `الشهر ${item.month}`
              ) || [],
          },
          title: { text: "الإيرادات الشهرية", align: "center" },
        };
      },
      reservationChartSeries() {
        return [
          {
            name: "الحجوزات",
            data:
              this.getCharts?.reservations?.map(
                (item) => item.reservation_count
              ) || [],
          },
        ];
      },
      reservationChartOptions() {
        return {
          chart: { type: "bar", height: 350 },
          xaxis: {
            categories:
              this.getCharts?.reservations?.map(
                (item) => `الشهر ${item.month}`
              ) || [],
          },
          title: { text: "عدد الحجوزات لكل شهر", align: "center" },
        };
      },

      statusChartSeries() {
        return (
          this.getCharts?.reservationsByStatus?.map((item) => item.count) || []
        );
      },
      statusChartOptions() {
        const statusLabels = {
          closed: "تم",
          canceled: "ملغى",
          waiting: "انتظار",
        };

        return {
          chart: {
            type: "pie",
            height: 250, // Adjust height here
          },

          labels:
            this.getCharts?.reservationsByStatus?.map(
              (item) => statusLabels[item.status] || item.status
            ) || [],
          title: { text: "الحجوزات حسب الحالة", align: "center" },
        };
      },

      genderChartSeries() {
        return (
          this.getCharts?.patientDemographics?.map((item) => item.count) || []
        );
      },
      genderChartOptions() {
        return {
          chart: {
            type: "pie",
            height: 250, // Adjust height here
          },

          labels:
            this.getCharts?.patientDemographics?.map((item) => item.gender) ||
            [],
          title: { text: "التوزيع حسب الجنس", align: "center" },
        };
      },

      newPatientChartSeries() {
        return [
          {
            name: "المرضى الجدد",
            data:
              this.getCharts?.newPatients?.map(
                (item) => item.new_patients_count
              ) || [],
          },
        ];
      },
      newPatientChartOptions() {
        return {
          chart: { type: "line", height: 350 },
          xaxis: {
            categories:
              this.getCharts?.newPatients?.map(
                (item) => `الشهر ${item.month}`
              ) || [],
          },
          title: { text: "عدد المرضى الجدد لكل شهر", align: "center" },
        };
      },
    },
    methods: {
      ...mapActions(["fetchCharts"]),
    },
  };
</script>

<style scoped>
  .charts {
    max-height: 82vh;
    overflow: auto;
    background-color: #f9f9f9;
    padding: 15px;
  }
  .v-card {
    border-radius: 12px;
    background: #ffffff;
  }
</style>
