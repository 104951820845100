<template>
  <v-dialog
    :value="PrescriptionDialog"
    v-if="PrescriptionDialog"
    :width="$vuetify.breakpoint.smAndDown ? '90%' : '75%'"
    persistent
  >
    <v-card v-click-outside="handleClickOutSide">
      <v-card-title class="headline mb-3">
        <h5 class="text-right pr-1">اضافة وصفة طبية</h5>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="text-center mb-2">
        <div class="text-right px-2">
          <h6>اسم المريض</h6>
          <h3>{{ patientData.fullname }}</h3>
          <h6>فصيلة الدم</h6>
          <h3>{{ patientData.blood_type }}</h3>
          <h6>الحساسية للادوية</h6>
          <h3>
            {{
              patientData.drug_allergy == null
                ? " لايوجد"
                : patientData.drug_allergy
            }}
          </h3>
          <h6>لقاح كورونا</h6>
          <h3>{{ patientData.corona_vaccine }}</h3>
        </div>
        <v-divider class="my-2"></v-divider>
        <v-form ref="prescription_form" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" md="5" v-if="isGovClinic == false">
                <v-combobox
                  clearable
                  @click:clear="clearAutocompleteFields"
                  @keyup="searchForMedicine"
                  @input="updatePrescriptionFields"
                  :search-input.sync="searchMedicine"
                  v-model="drugObject"
                  :items="getMedicines('all')"
                  label="اسم العلاج"
                  placeholder="بحث عن الاسم الطبي"
                  required
                  outlined
                  item-text="drugNameIndexed"
                  item-value="id"
                ></v-combobox>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  label="الجرعة"
                  placeholder="example:500mg"
                  v-model="dose"
                  outlined
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="route"
                  :items="drugRoutes"
                  label="الشكل الدوائي"
                  placeholder="Route"
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="repeating"
                  label="التكرار والمحلاظات"
                  required
                  dir="ltr"
                  outlined
                  ref="repeating_ref"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn
                  @click="addPrescriptionToList"
                  large
                  color="blue white--text ml-2"
                >
                  <v-icon dark> mdi-plus </v-icon>
                  اضافة
                </v-btn>
                <v-btn
                  v-if="isGovClinic"
                  @click="getPatientChronicDrugsFn"
                  large
                  color="purple lighten-1 white--text"
                  :loading="loadingSpinnerChronic"
                  :disabled="loadingSpinnerChronic"
                >
                  <v-icon dark> mdi-download </v-icon>
                  ادوية المراجع المزمنة
                </v-btn>
              </v-col>

              <v-col cols="12" class="text-right">
                <v-subheader>الوصفات</v-subheader>

                <div
                  v-for="(prescription, index) in currentPrescription"
                  :key="prescription.index"
                  class="ma-2 px-1"
                  style="
                    position: relative;
                    border: 1px solid #2196f3;
                    border-radius: 5px;
                    color: #2196f3;
                    font-size: 15px;
                  "
                >
                  <div>
                    <small>{{ prescription.date }}</small>
                  </div>
                  <div>{{ prescription.drug }}</div>
                  <div>
                    {{ prescription.dose }} - {{ prescription.route }} -
                    {{ prescription.repeating }}
                  </div>
                  <v-icon
                    @click="removePrescreptionFromList(index)"
                    color="red"
                    class="remove-prescription"
                    >mdi-close-box</v-icon
                  >
                </div>
              </v-col>
            </v-row>
            <v-alert dense outlined type="error" v-if="errorAlrt">
              حدث خطاء فشل في العملية
            </v-alert>
            <v-alert dense outlined type="success" v-if="successAlrt">
              العملية تمت بنجاح
            </v-alert>
            <v-divider></v-divider>
          </v-container>
        </v-form>
        <v-btn
          class="ml-1 mb-2 mb-md-0"
          color="success white--text"
          :loading="loadingSpinner"
          :disabled="loadingSpinner"
          @click="addPrescription"
        >
          حفظ
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
          <v-icon right>mdi-content-save-outline</v-icon>
        </v-btn>
        <v-btn
          class="ml-1 mb-2 mb-md-0"
          color="primary white--text"
          :loading="lsPrintSave"
          :disabled="lsPrintSave"
          @click="addPrintPrescription"
        >
          حفظ وطباعة
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
          <v-icon right>mdi-cloud-print-outline</v-icon>
        </v-btn>
        <v-btn
          class="mb-2 mb-md-0"
          color="secondary white--text"
          :loading="lsPrint"
          :disabled="lsPrint"
          @click="printPrescription"
        >
          طباعة
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
          <v-icon right>mdi-printer</v-icon>
        </v-btn>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" text @click="closeDialog"> اغلاق </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          اغلاق
        </v-btn>
      </template>
    </v-snackbar>
    <div class="d-none">
      <vue-html2pdf
        :show-layout="true"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="الوصفة الطبية"
        :pdf-quality="3"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
        @hasDownloaded="attemptPrint($event)"
      >
        <section
          slot="pdf-content"
          class="text-center"
          style="text-align: right; padding: 0 20px"
        >
          <section class="pdf-item" dir="rtl" style="height: 160px">
            <div style="display: block; text-align: right">
              <div style="display: inline-block; margin-left: 5px">
                <img
                  height="120"
                  width="120"
                  src="../../../../public/logo.png"
                  v-if="!isGovClinic"
                />
                <img
                  v-else-if="clinicData.clinic_logo != 'default'"
                  height="120"
                  width="120"
                  src="../../../../public/mpc_logo.png"
                />
              </div>
              <div style="display: inline-block">
                <h3>{{ clinicData.clinic_name }}</h3>
                <h3>{{ doctorData.user_info.fullname }}</h3>
                <h4>{{ clinicData.clinic_address }}</h4>
                <h4>موبايل:{{ clinicData.mobile_line1 }}</h4>
              </div>
            </div>
            <hr />
          </section>
          <section class="pdf-item" style="height: 100px">
            <h4 style="text-align: right">بيانات المراجع</h4>
            <div>
              <span>الاسم: </span>
              <span>{{ patientData.fullname }} </span>
            </div>
            <div>
              <span dir="rtl">{{ patientData.date_of_birth }} </span>
              <span>: تاريخ التولد </span>
            </div>
          </section>

          <section class="pdf-item" style="height: 20px">
            <div style="font-weight: bold">
              <span style="float: right"> الوصفة الطبية</span>
            </div>
            <div>
              <div
                style="
                  float: left;
                  padding: 10px;
                  font-size: 25px;
                  font-family: 'Courier New', Courier, monospace;
                "
              >
                Rx
              </div>
            </div>
          </section>
          <br />

          <section class="pdf-item" style="height: 720px">
            <ul style="text-align: left; padding-left: 10px">
              <li
                v-for="prescription in currentPrescription"
                :key="prescription.index"
                style="font-size: 18px"
              >
                {{ prescription.drug
                }}<span v-if="prescription.dose">-{{ prescription.dose }}</span
                >-{{ prescription.route }}-{{ prescription.repeating }}
              </li>
            </ul>
          </section>

          <section class="pdf-item" style="height: 40px">
            <div
              style="
                display: block;
                margin: 0 10px;
                padding: 5px;
                background: #eee;
                border-radius: 25px;
              "
            >
              <div style="display: inline-block; text-align: left; width: 33%">
                <span style="font-size: 14px">التوقيع</span>
              </div>

              <div
                style="display: inline-block; text-align: center; width: 33%"
              >
                <span style="font-size: 14px">
                  {{ new Date().toISOString().slice(0, 10) }}
                </span>
              </div>

              <div style="display: inline-block; text-align: right; width: 33%">
                <span>{{ doctorData.user_info.fullname }}</span>
              </div>
            </div>
          </section>
          <div
            v-if="!isEmpty(biometricData)"
            class="html2pdf__page-break"
          ></div>

          <section v-if="!isEmpty(biometricData)" class="pdf-item">
            <div style="font-weight: bold; padding: 20px 0 !important">
              <span style="float: right">
                <v-icon>mdi-pulse</v-icon> القياسات الحيوية
              </span>

              <br />
              <div style="text-align: left">
                <h3 v-if="biometricData.bloodPressureDia">
                  Blood preassure DIA:{{ biometricData.bloodPressureDia }}
                </h3>
                <h3 v-if="biometricData.bloodPressureSys">
                  Blood preassure SYS:{{ biometricData.bloodPressureSys }}
                </h3>
                <h3 v-if="biometricData.pulseRate">
                  Pulse rate:{{ biometricData.pulseRate }}
                </h3>
                <h3 v-if="biometricData.bodyTemprature">
                  Body temprature:{{ biometricData.bodyTemprature }}
                </h3>
                <h3 v-if="biometricData.spo2">Spo2:{{ biometricData.spo2 }}</h3>
                <h3 v-if="biometricData.weight">
                  Weight:{{ biometricData.weight }}
                </h3>
                <h3 v-if="biometricData.height">
                  Height:{{ biometricData.height }}
                </h3>
                <h3 v-if="biometricData.bodyMass">
                  Body mass index:{{ biometricData.bodyMass }}
                </h3>
                <h3 v-if="biometricData.rbs">RBS:{{ biometricData.rbs }}</h3>
              </div>
            </div>
          </section>
        </section>
      </vue-html2pdf>
    </div>
  </v-dialog>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import VueHtml2pdf from "vue-html2pdf";

  export default {
    components: { VueHtml2pdf },
    props: [
      "PrescriptionDialog",
      "id",
      "currentPrescription",
      "patientData",
      "clinicData",
      "doctorData",
      "biometricData",
      "reservationType",
    ],
    data() {
      return {
        base64ClinicLogo: null,
        snackbar: false,
        snackbarText: null,
        requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
        loadingSpinner: false,
        loadingSpinnerChronic: false,
        lsPrintSave: false,
        lsPrint: false,
        errorAlrt: false,
        successAlrt: false,
        Info: { prescription: [], id: 0 },
        drugId: null,
        drugObject: null,
        drugName: "",
        route: "",
        dose: "",
        drugRoutes: [
          "syrup",
          "bottle",
          "vial",
          "ampule",
          "tablet",
          "capsule",
          "liquid",
          "sachet",
          "cream",
          "gel",
          "soap",
          "spray",
          "drops",
          "plaster",
          "paste",
          "patch",
          "inhalation",
          "solution",
          "suspension",
          "suppository",
        ],
        repeating: "",
        searchMedicine: "",
        patientChronicDrugs: null,
      };
    },
    created() {
      if (this.isGovClinic) {
        this.fetchClinicMedicines(this.searchMedicine);
      }
    },
    computed: {
      ...mapGetters(["getMedicines", "isGovClinic", "getMedicineById"]),
      /*  filteredMedicines() {
        if (this.searchMedicine == "" || this.searchMedicine == null) {
          return this.getMedicines;
        }
        const searchTerm = this.searchMedicine.toLowerCase();

        // Use the Vuex getter to filter medicines
        return this.getMedicines.filter((medicine) =>
          medicine.drug_name.toLowerCase().includes(searchTerm)
        );
      }, */
      /*      filteredMedicines() {
        if (this.searchMedicine == "" || this.searchMedicine == null) {
          if (this.reservationType === "normal")
            var filteredMedicinesRes = this.getMedicines.filter(
              (medicine) => medicine.doctor_indexing === 0
            );
          else
          filteredMedicinesRes = this.getMedicines.filter(
              (medicine) => medicine.doctor_indexing != 0
            );
          return filteredMedicinesRes;
        }
        const searchTerm = this.searchMedicine.toLowerCase();
        // Filter medicines based on search term
        filteredMedicinesRes = this.getMedicines.filter((medicine) =>
          medicine.drug_name.toLowerCase().includes(searchTerm)
        );
        if (this.reservationType === "normal") {
          filteredMedicinesRes = filteredMedicinesRes.filter(
            (medicine) => medicine.doctor_indexing === 0
          );
        } else
        filteredMedicinesRes = filteredMedicinesRes.filter(
            (medicine) => medicine.doctor_indexing != 0
          );

        return filteredMedicinesRes;
      },*/
    },
    methods: {
      ...mapActions([
        "addDoctorPrescription",
        "fetchMedicines",
        "fetchClinicMedicines",
        "addMedicine",
        "getPatientChronicDrugs",
      ]),
      isEmpty(obj) {
        // Check if the object has no keys
        if (Object.keys(obj).length === 0) return true;

        // Check if any key has an empty ("") value
        return Object.values(obj).every((value) => value === "");
      },
      getPatientChronicDrugsFn() {
        this.loadingSpinnerChronic = true;
        this.getPatientChronicDrugs(this.patientData.id)
          .then((res) => {
            this.loadingSpinnerChronic = false;
            if (res && res.clinic_drugs && res.clinic_drugs.length > 0) {
              this.patientChronicDrugs = res.clinic_drugs;
              res.clinic_drugs.forEach((drug) => {
                let prescription = {
                  drug: drug.drug_name,
                  dose: drug.dosage,
                  route: drug.drug_form,
                  repeating: drug.pivot.quantity,
                  date: new Date().toISOString().slice(0, 10),
                };
                this.currentPrescription.push(prescription);
              });
              this.snackbarText = "تم استرجاع الأدوية بنجاح!";
              this.snackbar = true;
            } else {
              this.snackbarText = "لايوجد بيانات!";
              this.snackbar = true;
            }
          })
          .catch(() => {
            this.loadingSpinnerChronic = false;
          });
      },

      updatePrescriptionFields(value) {
        if (value && typeof value === "object") {
          this.drugId = value.id; // Extract the id from the selected object
        } else {
          this.drugId = null; // Allow custom input (not an object)
        }
        if (this.drugId) {
          var selectedMedicine = this.getMedicineById(this.drugId);

          console.log("selectedMedicine", selectedMedicine);
          console.log("drug id", this.drugId);
          // Check if the selectedMedicine is not null or undefined
          if (selectedMedicine) {
            this.dose = selectedMedicine.dosage || "";
            this.route = selectedMedicine.drug_form || "";
            this.drugName = selectedMedicine.drug_name || "";
          }
          this.$refs.repeating_ref.focus();
        } else {
          this.drugId = null;
          this.drugName = value;
        }
      },
      clearAutocompleteFields() {
        // Reset other fields when autocomplete is manually cleared
        console.log("Autocomplete clear");
        this.drugObject = null;
        this.dose = "";
        this.route = "";
        this.drugId = null;
        this.drugName = null;
      },

      addNewMedicine() {
        this.addMedicine(this.searchMedicine);
      },
      toDataURL(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
          var reader = new FileReader();
          reader.onloadend = function () {
            callback(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
      },

      fetchClinicMedFn() {
        //if (this.isGovClinic) {
        this.fetchClinicMedicines(this.searchMedicine);
        //}
      },
      searchForMedicine() {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          if (this.searchMedicine != "")
            this.fetchClinicMedicines(this.searchMedicine);
        }, 700);
      },
      handleClickOutSide(e) {
        if (e.target.className == "v-overlay__scrim") this.closeDialog();
      },
      closeDialog() {
        this.$emit("prescriptionDialogClose", false);
      },
      addPrescriptionToList() {
        var prescription = {};
        if (!this.drugName || !this.route || !this.repeating) {
          this.snackbarText = "الرجاء ملئ جميع الحقول";
          this.snackbar = true;
          return;
        }
        let today = new Date().toISOString().slice(0, 10);
        prescription = {
          drug: this.drugName,
          dose: this.dose,
          route: this.route,
          repeating: this.repeating,
          date: today,
          drugId: this.drugId,
        };
        this.currentPrescription.push(prescription);
        this.drugId = null;
        this.drugName = null;
        this.dose = null;
        this.route = null;
        this.repeating = null;
        this.drugObject = null;
      },
      removePrescreptionFromList(i) {
        this.currentPrescription.splice(i, 1);
      },
      addPrescription() {
        this.Info.prescription = JSON.stringify(this.currentPrescription);
        this.Info.id = this.id;
        this.loadingSpinner = true;

        this.addDoctorPrescription(this.Info)
          .then(() => {
            this.loadingSpinner = false;
            this.successAlrt = true;
            setTimeout(() => {
              this.successAlrt = false;
              this.errorAlrt = false;
              this.$refs.prescription_form.reset();
            }, 1000);
          })
          .catch(() => {
            this.errorAlrt = true;
            this.successAlrt = false;

            this.loadingSpinner = false;
          });
      },
      addPrintPrescription() {
        this.Info.prescription = JSON.stringify(this.currentPrescription);
        this.Info.id = this.id;
        this.lsPrintSave = true;

        this.addDoctorPrescription(this.Info)
          .then(() => {
            this.lsPrintSave = false;
            this.successAlrt = true;
            this.$refs.html2Pdf.generatePdf();
            setTimeout(() => {
              this.successAlrt = false;
              this.errorAlrt = false;
              this.$refs.prescription_form.reset();
            }, 1000);
          })
          .catch(() => {
            this.errorAlrt = true;
            this.successAlrt = false;

            this.lsPrintSave = false;
          });
      },
      attemptPrint(blob) {
        let url = URL.createObjectURL(blob);
        window.open(url); //opens the pdf in a new tab
      },
      printPrescription() {
        this.lsPrint = true;

        this.$refs.html2Pdf.generatePdf();
        this.lsPrint = false;
      },
    },
  };
</script>

<style scoped>
  .ltr-menu {
    direction: ltr !important;
    color: red;
  }
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  .remove-prescription {
    position: absolute;
    left: 2rem;
    top: 35%;
  }

  @-moz-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
</style>
