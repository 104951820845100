<template>
  <v-dialog v-if="CommentDialog" :value="CommentDialog" width="650">
    <v-card v-click-outside="closeDialog">
      <v-card-title class="headline mb-3">
        <h5 class="text-right pr-1">اضافة تشخيص للمريض</h5>
      </v-card-title>
      <v-card-text class="text-center mb-2">
        <v-alert dense outlined type="error" v-if="errorAlrt">
          حدث خطاء فشل في العملية
        </v-alert>
        <v-alert dense outlined type="success" v-if="successAlrt">
          عملية الاضافة تمت بنجاح
        </v-alert>
        <v-form ref="comment_form" lazy-validation>
          <v-textarea
            label="تشخيص الطبيب"
            :rules="requiredRule"
            v-model="doctorComment"
            rows="3"
            outlined
          ></v-textarea>
          <v-file-input
            class="d-flex"
            v-model="files"
            label="ارفاق ملفات"
            accept="image/*"
            outlined
            multiple
          ></v-file-input>
        </v-form>
        <!-- Display file previews -->
        <div class="mb-3 text-right"><h2>الملفات</h2></div>

        <div
          v-if="currentFiles && currentFiles.length"
          class="d-flex flex-wrap mt-3"
        >
          <a
            v-for="(file, index) in currentFiles"
            :key="index"
            :href="file"
            target="_blank"
            rel="noopener noreferrer"
            class="ma-2"
          >
            <v-img
              :src="file"
              class="ma-2"
              max-height="100"
              max-width="100"
            ></v-img>
          </a>
        </div>
        <div v-else class="text-right my-3">لايوجد ملفات</div>

        <v-btn
          color="indigo white--text"
          :loading="loadingSpinner"
          :disabled="loadingSpinner"
          @click="addComment"
          block
          large
        >
          حفظ
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" text @click="closeDialog"> اغلاق </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions } from "vuex";

  export default {
    props: ["CommentDialog", "id", "currentComment", "currentFiles"],

    watch: {
      currentComment(newVal) {
        this.doctorComment = newVal;
      },
    },

    data() {
      return {
        doctorComment: this.currentComment,
        requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
        loadingSpinner: false,
        errorAlrt: false,
        successAlrt: false,
        Info: { comment: null, id: 0 },
        formData: new FormData(),
        files: null,
      };
    },

    methods: {
      ...mapActions(["addDoctorComment"]),
      closeDialog() {
        this.$emit("commentDialogClose", false);
      },

      addComment() {
        console.log(this.doctorComment);
        if (!this.$refs.comment_form.validate()) return;

        this.loadingSpinner = true;
        this.formData = new FormData();
        this.formData.append("comment", this.doctorComment);
        this.formData.append("id", this.id);

        // Append selected files to FormData
        if (this.files && this.files.length) {
          this.files.forEach((file, index) => {
            this.formData.append(`files[${index}]`, file);
          });
        }
        this.addDoctorComment(this.formData)
          .then(() => {
            this.loadingSpinner = false;
            this.successAlrt = true;
            setTimeout(() => {
              this.successAlrt = false;
              this.errorAlrt = false;
              //this.$emit("commentDialogClose", false);
            }, 3000);
          })
          .catch(() => {
            this.errorAlrt = true;
            this.loadingSpinner = false;
          });
      },
    },
  };
</script>

<style scoped lang="scss">
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }

  @-moz-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
</style>
