<template>
  <div class="reservations">
    <v-container fluid class="px-1 px-sm-1 px-md-2 px-lg-3">
      <div class="title mb-1" style="font-family: cairo !important">
        سجل الزيارات
      </div>
      <!--  <v-alert
        v-model="newReservationAlrt"
        dismissible
        color="red"
        border="left"
        elevation="1"
        colored-border
        icon="mdi-bookmark-add"
      >
        تم اضافة حجز <strong>جديد</strong>
      </v-alert> -->
      <!-- counters start -->
      <v-list
        elevation="1"
        rounded
        style="max-height: 80px"
        class="overflow-y-auto mb-1"
      >
        <template>
          <div class="px-2">
            <v-chip
              v-if="isClinicAdmin"
              small
              class="ml-1 mb-1 primary--text grey lighten-4"
            >
              حجوزات العيادة: {{ getCounters.clinic_reservations }}
            </v-chip>
            <v-chip
              v-if="!isGovClinic"
              small
              class="ml-1 mb-1 primary--text grey lighten-4"
            >
              حجوزات الطبيب: {{ getCounters.reservations }}
            </v-chip>
            <v-chip
              small
              :color="resCount.color"
              class="ml-1 mb-1"
              v-for="(resCount, i) in reservationsCounters"
              :key="i"
            >
              {{ resCount.title }}: {{ getCounters[resCount.countName] }}
            </v-chip>
          </div>
        </template>
      </v-list>
      <!-- counters end -->

      <!--  <v-snackbar
        color="indigo"
        text
        v-model="newReservationAlrt"
        timeout="100000"
      >
        تم اضافة حجز <strong>جديد</strong>

        <template v-slot:action="{ attrs }">
          <v-btn
            color="indigo"
            text
            v-bind="attrs"
            @click="newReservationAlrt = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar> -->
      <v-snackbar v-model="snackbar">
        {{ snackbarMsg }}

        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
            اغلاق
          </v-btn>
        </template>
      </v-snackbar>
      <v-card class="pb-1">
        <v-card-title class="">
          <v-row dense>
            <v-col cols="12">
              <v-btn
                v-if="!getFeatures.includes('clinic_admin')"
                style="letter-spacing: 0"
                class="float-right ml-2 mb-2"
                @click="showAddNewPatientDialog"
                color="indigo white--text"
              >
                <v-icon left>mdi-plus-box-outline</v-icon>
                اضافة مراجع وحجز
              </v-btn>

              <v-text-field
                dense
                v-model="Info.keyword"
                append-icon="mdi-magnify"
                label="بحث"
                hide-details
                solo
                class="d-inline-block mt-0 mb-2 ml-0 ml-md-2"
                style="vertical-align: center; margin-top: 0"
                @keyup.enter="searchInReservations()"
              >
                <template v-slot:append>
                  <v-btn
                    style="vertical-align: center"
                    :loading="loadingSearch"
                    :disabled="loadingSearch"
                    color="blue darken-1"
                    plain
                    class="float-right my-auto"
                    @click="searchInReservations()"
                  >
                    <v-icon left>mdi-magnify</v-icon>

                    بحث
                  </v-btn>
                </template>
              </v-text-field>
              <v-select
                dense
                class="d-inline-block ml-0"
                v-model="Info.displayType"
                :items="displayOptions"
                item-text="name"
                item-value="val"
                label="نوع العرض"
                placeholder="اختر النوع"
                @change="filterReservations"
                solo
              ></v-select>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          align-center
          :headers="computedHeaders"
          :items="allPatientReservations"
          :items-per-page="itemPerPage"
          hide-default-footer
          fixed-header
          dir="rtl"
          style="overflow: hidden !important"
        >
          <template v-slot:item.patientFullname="{ item }">
            <div style="white-space: nowrap !important">
              <span class="ml-2" style="cursor: pointer">
                <v-icon size="20" @click="showEditPatient(item.patient)"
                  >mdi-pencil</v-icon
                >
              </span>
              <span
                @click="showPatientProfileDialog(item.patient.id, item.id)"
                class="fullname-style"
                >{{ item.patient.fullname }}</span
              >
            </div>
          </template>
          <!--  -->
          <template
            style="white-space: nowrap !important"
            v-slot:item.doctorName="{ item }"
          >
            <template v-if="getRole == 'clinic_assistant'">
              <v-select
                dense
                v-model="item.doctor.id"
                :items="getDoctorsList"
                item-value="id"
                :item-text="
                  (doctor) =>
                    doctor.user_info ? doctor.user_info.fullname : ''
                "
                outlined
                hide-details="true"
                @change="updateResDoctornFn(item.id, item.doctor.id)"
              ></v-select>
            </template>
            <template v-else>
              <div v-if="item.doctor && item.doctor.user_info.fullname">
                {{ item.doctor.user_info.fullname }}
              </div>
            </template>
          </template>
          <template v-slot:item.createdAt="{ item }">
            <span>{{ item.created_at.substring(0, 10) }}</span>
          </template>
          <template v-slot:item.patientType="{ item }">
            <v-select
              dense
              v-model="item.patient_type"
              :items="patienTypes"
              item-value="value"
              item-text="text"
              outlined
              hide-details="true"
              @change="updatePatientTypeFn(item.id, item.patient_type)"
            ></v-select>
          </template>
          <template v-slot:item.patientStatus="{ item }">
            <small v-if="item.patient_status == 'عادي'">{{
              item.patient_status
            }}</small>
            <small class="amber--text" v-if="item.patient_status == 'متوسط'">{{
              item.patient_status
            }}</small>
            <small class="red--text" v-if="item.patient_status == 'طارئ'">{{
              item.patient_status
            }}</small>
          </template>
          <template v-slot:item.status="{ item }">
            <!-- waiting -->
            <v-chip
              v-if="item.status == 'waiting'"
              class="ma-2"
              color="primary"
              outlined
              pill
              small
            >
              انتظار
              <v-icon right> mdi-alarm-snooze </v-icon>
            </v-chip>

            <!-- closed -->
            <v-chip
              v-if="item.status == 'closed'"
              class="ma-2"
              color="green"
              outlined
              pill
              small
            >
              تم
              <v-icon right> mdi-check </v-icon>
            </v-chip>
            <!-- canceled -->
            <v-tooltip v-if="item.status == 'canceled'" top>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="ma-2"
                  color="red"
                  outlined
                  pill
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  ملغى
                  <v-icon right> mdi-cancel </v-icon>
                </v-chip>
              </template>
              <span>{{
                item.cancel_reason_patient
                  ? item.cancel_reason_patient
                  : item.cancel_reason_doctor
              }}</span>
            </v-tooltip>
          </template>

          <!--   <template v-slot:item.cancelReason="{ item }">
            <span>{{
              item.cancel_reason_patient
                ? item.cancel_reason_patient
                : item.cancel_reason_doctor
            }}</span>
          </template> -->
          <template v-slot:item.patientBiometrics="{ item }">
            <v-btn
              icon
              dense
              color="red"
              class="mx-2"
              @click="showPatientBiometrics(item.id, item.patient)"
            >
              <v-icon>mdi-pulse</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.addPrescription="{ item }">
            <v-btn
              icon
              dense
              color="grey"
              class="mx-2"
              @click="
                addPrescription(
                  item.id,
                  item.prescription,
                  item.patient,
                  item.clinic,
                  item.doctor,
                  item.biometrics,
                  item.patient_type
                )
              "
            >
              <v-icon dark color="green">mdi-prescription</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.analysis="{ item }">
            <v-btn
              icon
              dense
              color="grey"
              class="mx-2"
              :class="{
                'shake-animation': shouldShake(item.analyses),
              }"
              @click="
                showAnalysisDialog(
                  item.id,
                  item.analyses,
                  item.patient,
                  item.clinic,
                  item.doctor
                )
              "
            >
              <v-icon dark color="purple">mdi-test-tube</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.addComment="{ item }">
            <v-btn
              icon
              color="indigo"
              class="mx-2"
              @click="addComment(item.id, item.doctor_comment, item.files)"
            >
              <v-icon dark>mdi-comment-plus</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.changeStatus="{ item }">
            <v-btn
              icon
              color="green"
              class="mx-2"
              @click="
                changeStatus(
                  item.id,
                  item.status,
                  item.patient.fullname,
                  item.doctor.fees
                )
              "
            >
              <v-icon dark>mdi-cash</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.sendNotification="{ item }">
            <v-btn
              icon
              color="amber"
              class="mx-2"
              @click="
                showNotificationDialog(
                  item.patient.id,
                  item.patient.device_token,
                  item.doctor.user_info.fullname,
                  item.patient.fullname
                )
              "
            >
              <v-icon dark>mdi-bell-plus</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <!-- pagination -->
        <v-pagination
          :disabled="disabledPagination"
          v-model="currentGet"
          :length="lastGet"
          total-visible="6"
        ></v-pagination>
        <!-- pagination -->
      </v-card>
    </v-container>
    <add-comment
      :id="reservationId"
      :currentComment="currentComment"
      :currentFiles="currentFiles"
      :CommentDialog="CommentDialog"
      @commentDialogClose="CommentDialog = $event"
    />
    <add-prescription
      :id="reservationId"
      :patientData="patientData"
      :clinicData="clinicData"
      :doctorData="doctorData"
      :biometricData="biometricData"
      :currentPrescription="currentPrescription"
      :PrescriptionDialog="PrescriptionDialog"
      :reservationType="reservationType"
      @prescriptionDialogClose="PrescriptionDialog = $event"
    />
    <patient-profile
      :patientData="patientData"
      :PatientProfileDialog="PatientProfileDialog"
      @patientProfileDialogClose="PatientProfileDialog = $event"
    /><patient-biometrics
      :biometricData="biometricData"
      :patientData="patientData"
      :reservationId="reservationId"
      :PatientBiometricsDialog="PatientBiometricsDialog"
      @patientBiometricsDialogClose="PatientBiometricsDialog = $event"
    />
    <add-patient
      :AddPatientDialog="AddPatientDialog"
      @addPatientDialogClose="AddPatientDialog = $event"
    />

    <change-status
      :id="reservationId"
      :patientFullname="patientFullname"
      :fees="doctorFees"
      :currentStatus="currentStatus"
      :ChangeStatusDialog="ChangeStatusDialog"
      @changeStatusDialogClose="ChangeStatusDialog = $event"
    />
    <single-notification
      :payload="notificationDialogPayload"
      :NotificationDialog="NotificationDialog"
      @singleNotificationDialogClose="NotificationDialog = $event"
    />
    <add-analysis
      :id="reservationId"
      :currentAnalyses="currentAnalyses"
      :patientData="patientData"
      :clinicData="clinicData"
      :doctorData="doctorData"
      :AnalysisDialog="AnalysisDialog"
      @analysisDialogClose="AnalysisDialog = $event"
    />
    <edit-patient-info
      :patientObject="patientObject"
      :EditPatientDialog="EditPatientDialog"
      @editPatientDialogClose="EditPatientDialog = $event"
    />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import AddPatient from "../../components/models/patient_reservations/AddPatient";
  import AddComment from "../../components/models/patient_reservations/AddComment";
  import AddPrescription from "../../components/models/patient_reservations/AddPrescription";
  import AddAnalysis from "../../components/models/patient_reservations/AddAnalysis";
  import ChangeStatus from "../../components/models/patient_reservations/ChangeStatus";
  import PatientProfile from "../../components/models/patient_reservations/PatientProfile";
  import PatientBiometrics from "../../components/models/patient_reservations/PatientBiometrics";
  import SingleNotification from "../../components/models/notifications/SingleNotification";
  import EditPatientInfo from "../../components/models/patient_reservations/EditPatientInfo";

  export default {
    name: "PatientReservationPage",
    components: {
      AddPatient,
      AddAnalysis,
      AddComment,
      ChangeStatus,
      AddPrescription,
      PatientProfile,
      SingleNotification,
      PatientBiometrics,
      EditPatientInfo,
    },
    data() {
      return {
        patientObject: {},
        EditPatientDialog: false,
        snackbar: false,
        snackbarMsg: "",
        disabledPagination: false,
        newReservationAlrt: false,
        reservationId: 0,
        currentComment: "",
        patientFullname: "",
        currentPrescription: [],
        currentAnalyses: [],
        currentFiles: [],
        currentStatus: "",
        doctorFees: 0,
        patientData: {},
        clinicData: {},
        doctorData: {},
        biometricData: {},
        AddPatientDialog: false,
        AddPatientChronicDrugsDialog: false,
        PatientProfileDialog: false,
        PatientBiometricsDialog: false,
        NotificationDialog: false,
        reservationType: null,
        notificationDialogPayload: {
          patientId: 0,
          deviceToken: "",
          doctorFullname: "",
          patientFullname: "",
        },
        CommentDialog: false,
        PrescriptionDialog: false,
        AnalysisDialog: false,
        ChangeStatusDialog: false,
        loadingSearch: false,
        headers: [
          { text: "اسم المراجع", value: "patientFullname" },
          { text: "اسم الطبيب", value: "doctorName" },
          { text: "تاريخ التقديم", value: "createdAt" },
          { text: "تاريخ المراجعة", value: "visit_date" },
          { text: "وقت المراجعة", value: "visit_time" },
          { text: "حالة المراجع", value: "patientType" },
          { text: "الشكوى الرئيسية", value: "patient_comment" },
          { text: "تشخيص الطبيب", value: "doctor_comment" },
          { text: "الحالة", value: "status" },
          { text: "الحالة والدفع", value: "changeStatus" },
          { text: "القياسات الحيوية", value: "patientBiometrics" },
          { text: "الوصفة الطبية", value: "addPrescription" },
          { text: "الفحوصات", value: "analysis" },
          { text: "اضافة تشخيص", value: "addComment" },
          { text: "ارسال اشعارات", value: "sendNotification" },
        ],
        itemPerPage: 10,
        selectedPage: 1,
        Info: {
          page: 1,
          keyword: "",
          displayType: "all",
        },
        patienTypes: [
          { text: "عامة", value: "normal" },
          { text: "مزمنة", value: "chronic" },
        ],
        reservationsCounters: [
          {
            title: "حجوزات اليوم",
            countName: "reservations_today",
            color: "dark--text grey lighten-4",
          },
          {
            title: "حجوزات الشهر",
            countName: "reservations_month",
            color: "dark--text grey lighten-4",
          },
          {
            title: "حجوزات السنة",
            countName: "reservations_year",
            color: "dark--text grey lighten-4",
          },
          {
            title: "حجوزات تامة",
            countName: "reservations_closed",
            color: "green--text grey lighten-4",
          },
          {
            title: "حجوزات قيدالانتظار",
            countName: "reservations_waiting",
            color: "indigo--text grey lighten-4",
          },
          {
            title: "حجوزات ملغاة",
            countName: "reservations_canceled",
            color: "red--text grey lighten-4",
          },
        ],
      };
    },
    mounted() {
      console.log(" this.getUser.id:", this.getUser);

      window.Echo.channel("clinic." + this.getClinicId).listen(
        "PatientAnalysisCompleteEvent",
        (event) => {
          console.log("Event received:", event);
          if (event.userId == this.getUser.id) {
            // Show browser notification
            if (Notification.permission === "granted") {
              new Notification("تم اكمال التحليل", {
                body: event.message,
              });
            } else if (Notification.permission !== "denied") {
              Notification.requestPermission().then(function (permission) {
                if (permission === "granted") {
                  new Notification("تم اكمال التحليل", {
                    body: event.message,
                  });
                }
              });
            }
          }
        }
      );

      this.disabledPagination = true;
      this.fetchCounters();
      if (this.getRole != "clinic_assistant")
        this.fetchInterval = setInterval(() => {
          this.fetchPatientReservations(this.Info)
            .then(() => (this.disabledPagination = false))
            .catch(() => (this.disabledPagination = false));
        }, 30000);
      else {
        this.fetchInterval = setInterval(() => {
          this.fetchPatientReservations(this.Info)
            .then(() => (this.disabledPagination = false))
            .catch(() => (this.disabledPagination = false));
        }, 60000);
      }
    },
    beforeDestroy() {
      // Clear the interval when the component is destroyed to avoid memory leaks
      if (this.getRole != "clinic_assistant") clearInterval(this.fetchInterval);
    },
    created() {
      if (this.getFeatures && this.getFeatures.includes("clinic_admin")) {
        this.Info.displayType = "doctor";
      } else {
        this.Info.displayType = "all";
      }
      if (this.getRole == "clinic_assistant") this.fetchDoctorsList();

      this.disabledPagination = true;
      this.fetchPatientReservations(this.Info)
        .then(() => (this.disabledPagination = false))
        .catch(() => (this.disabledPagination = false));
    },
    watch: {
      currentGet(newVal) {
        if (this.Info.displayType != "all") this.Info.keyword = "";
        this.Info.page = newVal;
        this.disabledPagination = true;
        this.fetchPatientReservations(this.Info)
          .then(() => (this.disabledPagination = false))
          .catch(() => (this.disabledPagination = false));
      },
    },
    computed: {
      ...mapGetters([
        "allPatientReservations",
        "getCounters",
        "getRole",
        "getPatientReservation",
        "getFeatures",
        "isGovClinic",
        "isClinicAdmin",
        "getDoctorsList",
        "getClinicId",
        "getUser",
      ]),
      displayOptions() {
        const defaultDisplayOptions = [
          {
            name: "جميع الزيارات",
            val: "all",
          },
          {
            name: "عرض زيارات اليوم",
            val: "today",
          },
          {
            name: "عرض زيارات الشهر",
            val: "month",
          },
          {
            name: "عرض زيارات السنة",
            val: "year",
          },
        ];

        if (this.getFeatures && this.getFeatures.includes("clinic_admin")) {
          return [
            {
              name: "زيارات الطبيب",
              val: "doctor",
            },
            ...defaultDisplayOptions,
          ];
        } else {
          // If false, only include the default options
          return defaultDisplayOptions;
        }
      },
      computedHeaders() {
        if (this.getRole == "secretary")
          return this.headers.filter(function (header) {
            if (
              header.text !== "اضافة تشخيص" &&
              header.text != "الوصفة الطبية" &&
              header.text != "الفحوصات" &&
              header.text != "طباعة الهوية"
            )
              return header;
          });
        else if (this.getRole == "clinic_assistant")
          return this.headers.filter(function (header) {
            if (
              header.text !== "اضافة تشخيص" &&
              header.text != "الوصفة الطبية" &&
              header.text != "ارسال اشعارات" &&
              header.text != "القياسات الحيوية"
            )
              return header;
          });
        else if (this.getRole == "doctor")
          return this.headers.filter(function (header) {
            if (header.text !== "اسم الطبيب" && header.text != "طباعة الهوية")
              return header;
          });
        else return this.headers;
      },
      currentGet: {
        get() {
          return this.$store.getters.getPrCurrentPage;
        },
        set(value) {
          this.$store.dispatch("setPrCurrentPage", value);
        },
      },
      lastGet: {
        get() {
          return this.$store.getters.getPrLastPage;
        },
      },
    },

    methods: {
      ...mapActions([
        "fetchPatientReservations",
        "addReservation",
        "fetchCounters",
        "fetchBiometricsOfReservation",
        "fetchPatientMedicalHistory",
        "fetchDoctorsList",
        "changeResDoctor",
        "updatePatientType",
      ]),
      showPrintBadgeDialog(patient, clinic) {
        if (patient) {
          this.patientData = patient;
          this.clinicData = clinic;
          this.PrintBadgeDialog = true;
        }
      },

      shouldShake(analyses) {
        if (!analyses) return false;
        for (const analysis of analyses) {
          if (analysis.files && analysis.files.length > 0) {
            console.log(true);
            return true;
          }
        }
        return false;
      },
      updateResDoctornFn(resId, docId) {
        this.changeResDoctor({ id: resId, doctorId: docId })
          .then((res) => {
            if (res == "updated") {
              this.snackbar = true;
              this.snackbarMsg = "العملية تمت بنجاح";
            }
          })
          .catch(() => {
            this.snackbar = true;
            this.snackbarMsg = "حدث خطاء فشل في العملية";
          });
      },
      updatePatientTypeFn(resId, type) {
        this.updatePatientType({ id: resId, type: type })
          .then((res) => {
            if (res == "updated") {
              this.snackbar = true;
              this.snackbarMsg = "العملية تمت بنجاح";
            }
          })
          .catch(() => {
            this.snackbar = true;
            this.snackbarMsg = "حدث خطاء فشل في العملية";
          });
      },
      searchInReservations() {
        this.Info.page = 1;
        this.loadingSearch = true;
        this.Info.displayType = "all";
        this.disabledPagination = true;
        this.fetchPatientReservations(this.Info)
          .then(() => {
            this.loadingSearch = false;
            this.disabledPagination = false;
          })
          .catch(() => {
            this.loadingSearch = false;
            this.disabledPagination = false;
          });
      },
      showNotificationDialog(
        patientId,
        deviceToken,
        doctorFullname,
        patientFullname
      ) {
        this.notificationDialogPayload.patientId = patientId;
        this.notificationDialogPayload.deviceToken = deviceToken;
        this.notificationDialogPayload.doctorFullname = doctorFullname;
        this.notificationDialogPayload.patientFullname = patientFullname;
        this.NotificationDialog = true;
      },
      filterReservations() {
        this.Info.page = 1;
        this.Info.keyword = "";
        this.disabledPagination = true;
        this.fetchPatientReservations(this.Info)
          .then(() => (this.disabledPagination = false))
          .catch(() => (this.disabledPagination = false));
      },
      showAddNewPatientDialog() {
        this.AddPatientDialog = true;
      },
      showPatientCardDialog() {
        this.AddPatientChronicDrugsDialog = true;
      },
      addComment(id, comment, files) {
        this.reservationId = id;
        this.currentComment = comment;
        this.currentFiles = files;

        this.CommentDialog = true;
      },
      showEditPatient(patient) {
        this.patientObject = patient;
        this.EditPatientDialog = true;
      },
      showPatientProfileDialog(patientId, resId) {
        this.fetchPatientMedicalHistory(patientId).then(() => {
          var patientNewData = this.getPatientReservation(resId).patient;
          if (patientNewData.medical_history == null)
            patientNewData.medical_history = {};
          this.patientData = patientNewData;
          this.PatientProfileDialog = true;
        });
      },
      showPatientBiometrics(id, patientData) {
        this.fetchBiometricsOfReservation(id).then(() => {
          var bio = this.getPatientReservation(id).biometrics;
          console.log(bio);
          if (bio == null) this.biometricData = {};
          else this.biometricData = bio;
          this.patientData = patientData;
          this.reservationId = id;
          this.PatientBiometricsDialog = true;
        });
      },
      addPrescription(
        id,
        prescription,
        patient,
        clinic,
        doctor,
        biometricData,
        patientType
      ) {
        /*  if (status != "closed") {
        this.snackbar = true;
        this.snackbarMsg = "الرجاء تغيير الحالة قبل اضافة وصفة";
      } else { */
        this.reservationType = patientType;
        this.biometricData = biometricData;
        this.reservationId = id;
        this.patientData = patient;
        this.clinicData = clinic;
        this.doctorData = doctor;
        this.currentPrescription = prescription;
        this.PrescriptionDialog = true;
        //}
      },
      showAnalysisDialog(id, analyses, patient, clinic, doctor) {
        this.reservationId = id;
        this.currentAnalyses = analyses;
        this.patientData = patient;
        this.clinicData = clinic;
        this.doctorData = doctor;
        this.AnalysisDialog = true;
        //}
      },
      changeStatus(id, status, patientFullname, fees) {
        this.doctorFees = fees;
        this.reservationId = id;
        this.currentStatus = status;
        this.patientFullname = patientFullname;
        this.ChangeStatusDialog = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  /* Define the CSS animation */
  @keyframes shake {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
    75% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateX(0);
    }
  }

  /* Apply the animation to elements with the shake-animation class */
  .shake-animation {
    animation: shake 0.5s ease infinite;
  }
  .v-input__append-inner {
    margin: 0 !important;
  }

  .reservations {
    max-height: 82vh;
    overflow: auto;

    .fullname-style {
      font-size: 14px;
      font-weight: bold;
      &:hover {
        color: #1976d2;
        cursor: pointer;
      }
    }
    .noScroll {
      overflow: hidden !important;
    }
  }
</style>
