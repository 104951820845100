<template>
  <div class="app-bar-page">
    <v-app-bar app class="bcreaml1 pa-0" dir="rtl" elevation="0">
      <v-toolbar-title class="d-none d-md-inline">
        <v-btn color="primary" style="letter-spacing: 0" to="main">
          <span v-if="getUser.role == 'clinic_assistant'"> الاستعلامات </span>
          <span v-else-if="getSubscriptionType == 'inactive'"
            ><v-icon color="#d90429" size="25" left>mdi-cancel</v-icon>
            غيرفعال</span
          >
          <span v-else-if="getSubscriptionType == 'expired'"
            ><v-icon color="#d90429" size="25" left>mdi-cancel</v-icon> منتهي
            الصلاحية</span
          >
          <span v-else-if="getSubscriptionType == 'golden'"
            ><v-icon color="#ffd500" size="25" left>mdi-check-decagram</v-icon>
            الاشتراك الذهبي</span
          >
          <span v-else-if="getSubscriptionType == 'platinum'"
            ><v-icon color="#d3d6d8" size="25" left>mdi-check-decagram</v-icon>
            الاشتراك البلاتيني</span
          >
          <span v-else-if="getSubscriptionType == 'diamond'"
            ><v-icon color="#caf0f8" size="25" left>mdi-check-decagram</v-icon>
            الاشتراك الماسي</span
          ><span v-else-if="getSubscriptionType == 'trial-3'"
            ><v-icon color="#fafafa" size="25" left>mdi-charity</v-icon> اشتراك
            تجريبي</span
          ><span v-else-if="getSubscriptionType == 'trial-6'"
            ><v-icon color="#fafafa" size="25" left>mdi-charity</v-icon>
            اشتراك تجريبي
          </span>
        </v-btn>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <span style="font-size: 16px" class="d-none d-md-inline"
        >{{ clinicName }} - {{ fullname }}</span
      >
      <span style="font-size: 14px" class="d-inline d-md-none"
        >{{ clinicName }} - {{ fullname }}</span
      >

      <v-spacer></v-spacer>
      <!-- doctor status -->
      <v-menu left bottom>
        <v-list>
          <v-list-item-group>
            <v-list-item
              v-for="(status, i) in activityStatus"
              :key="i"
              @click="changeDoctorStatus(status.val)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon :color="status.color">mdi-circle-medium</v-icon>
                  {{ status.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <template v-if="currentStatus" v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon
              :color="
                currentStatus == 'online'
                  ? 'green'
                  : currentStatus == 'full'
                  ? 'yellow darken-1'
                  : 'red'
              "
              >mdi-circle-medium</v-icon
            >
            <v-icon> mdi-doctor </v-icon>
          </v-btn>
        </template>
      </v-menu>
      <!-- status end -->
      <v-menu left bottom>
        <v-list>
          <v-list-item-group>
            <v-list-item
              v-for="(baroption, i) in baroptions"
              :key="i"
              :to="baroption.route"
            >
              <v-list-item-content>
                <v-list-item-title>{{ baroption.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="logoutFunc">
              <v-list-item-content>
                <v-list-item-title>تسجيل الخروج</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
          <!-- <span>{{ fullname }} <v-icon>mdi-account</v-icon> </span> -->
        </template>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: "AppBar",
    data: function () {
      return {
        fullname: "",
        clinicName: "",
        baroptions: [
          {
            name: "ادارة الحساب",
            route: "info",
          },
        ],
        currentStatus: "online",
        activityStatus: [
          { name: "متواجد", val: "online", color: "green" },
          { name: "غير متواجد", val: "offline", color: "red" },
          { name: "مكتمل العدد", val: "full", color: "yellow" },
        ],
      };
    },
    methods: {
      ...mapActions(["updateDoctorStatus", "signout"]),
      logoutFunc() {
        if (localStorage.getItem("user_token"))
          this.signout().then(() => {
            this.$router.push("/");
          });
      },
      changeDoctorStatus(newDoctorStatus) {
        this.updateDoctorStatus(newDoctorStatus)
          .then((msg) => {
            if (msg == "doctor status updated") {
              this.currentStatus = newDoctorStatus;
            }
          })
          .catch(() => {
            this.successAlrt = false;
            this.errorAlrt = true;
          });
      },
    },
    computed: {
      ...mapGetters([
        "authenticated",
        "isGovClinic",
        "getUser",
        "getSubscriptionType",
      ]),
    },
    created() {
      this.fullname = this.authenticated.fullname;
      this.clinicName =
        this.authenticated.role == "clinic_assistant"
          ? this.authenticated.clinicAssistantInfo.clinic.clinic_name
          : this.authenticated.doctorInfo.clinic.clinic_name;
      this.currentStatus =
        this.authenticated.role != "clinic_assistant"
          ? this.getUser.doctorInfo.activity_status
          : null;
    },
  };
</script>
<style scopped lang="scss">
  .app-bar-page {
    .app-bar {
      letter-spacing: normal !important;
    }
  }
</style>
