<template>
  <v-dialog
    v-if="ChangeStatusDialog"
    :value="ChangeStatusDialog"
    width="450"
    persistent
  >
    <v-card v-click-outside="handleClickOutSide">
      <v-card-title class="headline mb-3">
        <h5 class="text-right pr-1">
          الحالة والدفع:
          <span style="font-weight: 100; font-size: 18px">
            {{ patientFullname }}</span
          >
        </h5>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="text-center mb-2">
        <v-alert dense outlined type="error" v-if="errorAlrt">
          {{ errorAlrtText }}
        </v-alert>
        <v-alert dense outlined type="success" v-if="successAlrt">
          العملية تمت بنجاح
        </v-alert>
        <v-form v-if="step == 2" ref="otp_form" lazy-validation>
          <v-avatar color="grey lighten-3" size="72" class="mb-6">
            <span class="black--text text-h5">{{ counter }}</span>
          </v-avatar>
          <v-text-field
            v-model="Info.otp"
            label="رمز التاكيد"
            outlined
            dense
            :rules="requiredRule"
          ></v-text-field>
          <v-btn
            block
            color="indigo white--text"
            :loading="loadingSpinner"
            :disabled="loadingSpinner"
            @click="changeCurrentStatus"
          >
            حفظ وتاكيد العملية
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-form>
        <v-form v-if="step == 1" ref="status_form" lazy-validation>
          <v-select
            item-text="name"
            item-value="val"
            :items="states"
            label="اختر الحالة"
            v-model="Info.status"
            outlined
            :rules="requiredRule"
          >
          </v-select>
          <v-select
            v-if="Info.status == 'closed'"
            item-text="text"
            item-value="val"
            :items="paymentMethods"
            label="طريقة الدفع"
            v-model="Info.paymentMethod"
            outlined
            :rules="requiredRule"
          >
          </v-select>

          <v-text-field
            v-if="
              Info.status == 'closed' &&
              (Info.paymentMethod == 'card' ||
                Info.paymentMethod == 'insurance')
            "
            :label="getLabel()"
            v-model="Info.insuranceCardNumber"
            outlined
            :rules="requiredRule"
          ></v-text-field>

          <v-select
            v-if="Info.paymentMethod == 'installment'"
            :items="patientTypes"
            item-text="text"
            item-value="val"
            label="نوع المراجع"
            v-model="Info.patientType"
            outlined
            :rules="requiredRule"
          >
          </v-select>
          <v-select
            v-if="Info.paymentMethod == 'installment'"
            v-model="Info.cardType"
            :items="getCardTypes"
            item-text="arabic_name"
            item-value="id"
            label="نوع البطاقة"
            placeholder="اختر النوع"
            required
            :rules="requiredRule"
            outlined
          ></v-select>
          <v-select
            v-if="Info.paymentMethod == 'installment'"
            :items="months"
            label="عدد اشهر التقسيط"
            v-model="Info.months"
            outlined
            :rules="requiredRule"
          >
          </v-select>
          <v-text-field
            v-if="Info.paymentMethod == 'installment'"
            outlined
            :rules="requiredRule"
            v-model="Info.cardNumber"
            placeholder="رقم البطاقة"
            label="رقم البطاقة"
            type="number"
            maxlength="16"
          ></v-text-field>

          <v-text-field
            v-if="Info.paymentMethod == 'installment' && Info.cardType == 2"
            outlined
            :rules="requiredRule"
            v-model="Info.cardAccountNumber"
            placeholder="رقم الحساب"
            label="رقم الحساب"
            maxlength="10"
            minlength="10"
            type="number"
          ></v-text-field>

          <v-text-field
            v-if="Info.status == 'closed'"
            label="المبلغ"
            type="number"
            v-model="Info.amount"
            append-icon="mdi-currency-usd"
            outlined
            :rules="requiredRule"
          ></v-text-field>

          <v-textarea
            v-if="Info.status == 'canceled'"
            label="سبب الالغاء"
            :rules="requiredRule"
            v-model="Info.cancelReason"
            rows="2"
            outlined
          ></v-textarea>
          <v-btn
            block
            color="indigo white--text"
            :loading="loadingSpinner"
            :disabled="loadingSpinner"
            @click="changeCurrentStatus"
          >
            حفظ
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" text @click="closeDialog"> اغلاق </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";

  export default {
    props: [
      "ChangeStatusDialog",
      "id",
      "currentStatus",
      "patientFullname",
      "fees",
    ],
    watch: {
      currentStatus(newVal) {
        this.Info.status = newVal;
        this.Info.amount = this.fees;
      },
    },
    computed: {
      ...mapGetters(["getCardTypes"]),
    },
    created() {
      this.fetchCardTypes();
    },
    data() {
      return {
        c: "",
        counter: 120,
        step: 1,
        states: [
          { val: "waiting", name: "انتظار" },
          { val: "closed", name: "تم" },
          { val: "canceled", name: "ملغى" },
        ],
        paymentMethods: [
          { val: "cash", text: "نقد" },
          /* { val: "insurance", text: "ضمان صحي" },

            { val: "card", text: "باص" }, */

          /*         { val: "installment", text: "تقسيط" },
           */
        ],
        patientTypes: [
          { val: 1, text: "متقاعد" },
          { val: 2, text: "موظف" },
        ],
        requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
        loadingSpinner: false,
        errorAlrt: false,
        successAlrt: false,
        Info: {
          status: this.currentStatus,
          id: 0,
          paymentMethod: null,
          amount: null,
          months: null,
          patientType: null,
          cardType: null,
          cardNumber: null,
          cardAccountNumber: null,
          cancelReason: "",
          otp: null,
          sessionId: null,
        },
        months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 18, 24, 36],
        errorAlrtText: "حدث خطاء فشل في العملية ",
      };
    },

    methods: {
      ...mapActions(["changeStatus", "fetchCardTypes"]),
      getLabel() {
        if (
          this.Info.status == "closed" &&
          (this.Info.paymentMethod == "card" ||
            this.Info.paymentMethod == "insurance")
        ) {
          return this.Info.paymentMethod == "card"
            ? "رقم الباص"
            : "رقم التأمين";
        } else {
          return ""; // Set the default label or an empty string as needed
        }
      },
      changeCurrentStatus() {
        if (this.step == 1)
          if (!this.$refs.status_form.validate()) return;
          else if (this.step == 2) if (!this.$refs.otp_form.validate()) return;

        this.Info.id = this.id;
        this.loadingSpinner = true;

        this.changeStatus(this.Info)
          .then((response) => {
            console.log(response);
            if (response.success == "true" && response.message == "enterOtp") {
              this.loadingSpinner = false;
              this.successAlrt = false;
              this.errorAlrt = false;
              this.Info.sessionId = response.sessionId;
              this.step = 2;
              this.c = setInterval(() => {
                if (this.counter > 0) {
                  this.counter--;
                  if (this.counter < 10) {
                    this.counter = ("0" + this.counter).slice(-2);
                  }
                } else {
                  this.step = 1;
                  this.counter = 120;
                  clearInterval(this.c);
                  this.Info.otp = null;
                  this.Info.sessionId = null;
                }
              }, 1000);
            } else if (
              response.success == true &&
              response.message == "created"
            ) {
              this.loadingSpinner = false;
              this.step = 1;
              this.counter = 120;
              clearInterval(this.c);
              this.Info.otp = null;
              this.Info.sessionId = null;
              this.successAlrt = true;
              this.errorAlrt = false;
              setTimeout(() => {
                this.successAlrt = false;
                this.errorAlrt = false;
                this.closeDialog();
              }, 3000);
            } else if (response.message == "updated") {
              this.loadingSpinner = false;
              this.Info.otp = null;
              this.Info.sessionId = null;
              this.step = 1;
              this.successAlrt = true;
              this.errorAlrt = false;
              setTimeout(() => {
                this.successAlrt = false;
                this.errorAlrt = false;
                this.Info.insuranceCardNumber = null;
                this.Info.amount = null;

                this.closeDialog();
              }, 3000);
            }
          })
          .catch((err) => {
            if (!err.response)
              this.errorAlrtText = "مشكلة في خدمات التقسيط يرجى المحاولة لاحقا";
            else if (
              err.response.status == 504 ||
              err.response.status == 500 ||
              err.response.status == 503 ||
              err.response.status == 501
            )
              this.errorAlrtText = "مشكلة في خدمات التقسيط يرجى المحاولة لاحقا";
            else if (err.response.data.message == "not found")
              this.errorAlrtText = "لاتمتلك الصلاحية الكافية";
            else if (err.response.data.message == "forbiden")
              this.errorAlrtText = "لايمكنك التعديل بعد اتمام العملية";
            else if (err.response.data.message == "error in status")
              this.errorAlrtText = "مشكلة في حالة الزيارة";
            else if (err.response.data.message == "not eligible")
              this.errorAlrtText = err.response.data.aqsatiMessage;
            else if (err.response.data.message == "failed installment")
              this.errorAlrtText = err.response.data.aqsatiMessage;
            else if (err.response.data.message == "missingArguments")
              this.errorAlrtText = "الرجاء ملئ جميع الحقول";
            else if (err.response.data.errors.status) {
              if (
                err.response.data.errors.status[0] ==
                "The status field is required."
              )
                this.errorAlrtText = "حقل الحالة مطلوب";
              else if (
                err.response.data.errors.status[0] ==
                "The selected status is invalid."
              )
                this.errorAlrtText = "مشكلة في حالة الزيارة";
            } else if (err.response.data.errors.paymentMethod) {
              if (
                err.response.data.errors.paymentMethod[0] ==
                "The payment method field is required when status is closed."
              )
                this.errorAlrtText = "الرجاء اختيار طريقة الدفع";
              else if (
                err.response.data.errors.paymentMethod[0] ==
                "The given data was invalid."
              )
                this.errorAlrtText = "الرجاء اختيار طريقة الدفع";
              else if (
                err.response.data.errors.paymentMethod[0] ==
                "The selected payment method is invalid."
              )
                this.errorAlrtText = "الرجاء اختيار طريقة الدفع";
            } else if (err.response.data.errors.amount) {
              if (
                err.response.data.errors.amount[0] ==
                "The amount field is required when status is closed."
              )
                this.errorAlrtText = "حقل المبلغ مطلوب";
            } else if (err.response.data.errors.cardNumber) {
              if (err.response.data.errors.cardNumber[0] == "validation.luhn")
                this.errorAlrtText = "البطاقة التي ادخلتها غير صالحة";
            }
            //
            else this.errorAlrtText = "حدث خطاء, فشل في العملية";
            this.loadingSpinner = false;
            this.successAlrt = false;
            this.errorAlrt = true;
          });
      },
      closeDialog() {
        this.Info.status = this.currentStatus;
        this.Info.paymentMethod = null;
        this.Info.amount = null;
        this.Info.months = null;
        this.Info.patientType = null;
        this.Info.cardType = null;
        this.Info.cardNumber = null;
        this.Info.cardAccountNumber = null;
        this.Info.cancelReason = null;
        this.successAlrt = false;
        this.errorAlrt = false;
        this.loadingSpinner = false;

        this.$emit("changeStatusDialogClose", false);
      },
      handleClickOutSide(e) {
        if (e.target.className == "v-overlay__scrim") this.closeDialog();
      },
    },
  };
</script>

<style scoped lang="scss">
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }

  @-moz-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
</style>
