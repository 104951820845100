import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/global/Login.vue";
import Index from "../views/dashboard/Index.vue";
import MainPage from "../views/dashboard/MainPage.vue";
import MedicalAdvicesPage from "../views/dashboard/MedicalAdvicesPage.vue";
import OffersPage from "../views/dashboard/OffersPage.vue";
import OffersSubscriptions from "../views/dashboard/OffersSubscriptionsPage.vue";
import NotificationsPage from "../views/dashboard/NotificationsPage.vue";
import PatientReservationsPage from "../views/dashboard/PatientReservationsPage.vue";
import PaymentsPage from "../views/dashboard/PaymentsPage.vue";
import PatientChronicBookPage from "../views/dashboard/PatientChronicBookPage.vue";
import UserInfo from "../views/dashboard/UserInfo.vue";
import ChartsPage from "../views/dashboard/ChartsPage.vue";
import store from "../store";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },

  {
    path: "/dashboard/",
    name: "Index",
    component: Index,
    redirect: "/dashboard/main",
    meta: { requiresAuth: true },
    children: [
      {
        path: "main",
        name: "MainPage",
        component: MainPage,
      },

      {
        path: "advices",
        name: "MedicalAdvicesPage",
        component: MedicalAdvicesPage,
      },

      {
        path: "offers",
        name: "OffersPage",
        component: OffersPage,
      },
      {
        path: "chronicBook",
        name: "ChronicBookPage",
        component: PatientChronicBookPage,
      },

      {
        path: "offersSubscriptions",
        name: "OffersSubscriptionsPage",
        component: OffersSubscriptions,
      },
      {
        path: "notifications",
        name: "NotificationsPage",
        component: NotificationsPage,
      },
      {
        path: "charts",
        name: "ChartsPage",
        component: ChartsPage,
      },
      {
        path: "reservations",
        name: "PatientReservationsPage",
        component: PatientReservationsPage,
      },
      {
        path: "payments",
        name: "PaymentsPage",
        component: PaymentsPage,
      },

      {
        path: "info",
        name: "UserInfo",
        component: UserInfo,
      },
    ],
    beforeEnter: (to, from, next) => {
      if (
        to.matched.some((record) => record.meta.requiresAuth) &&
        !store.getters["authenticated"]
      ) {
        return next({
          name: "Login",
        });
      }
      next();
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
