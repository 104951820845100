<template>
  <v-dialog
    :value="AddPatientDialog"
    v-if="AddPatientDialog"
    :width="$vuetify.breakpoint.smAndDown ? '95%' : '90%'"
    persistent
  >
    <v-card>
      <v-card-title class="headline mb-3">
        <v-icon @click="closeDialog" color="black" size="30">mdi-close</v-icon>

        <h5 class="mx-auto pr-1">اضافة حجز لمراجع غير مسجل</h5>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="text-center mb-2">
        <v-container fluid>
          <v-form
            ref="new_patient_form"
            v-model="newpatient_valid"
            lazy-validation
          >
            <v-row class="text-right">
              <v-col cols="12" sm="3" v-if="isGovClinic">
                <v-text-field
                  label="رمز الاستجابة السريع"
                  @input="getPatientDataByUuid"
                  outlined
                  clearable
                  append-icon="mdi-qrcode"
                  v-model="patientUuid"
                  @click:clear="resetFields"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-combobox
                  @keyup="searchForPatients"
                  :search-input.sync="patientNameForSearch"
                  label="اسم المراجع"
                  outlined
                  small-chips
                  :rules="requiredRule"
                  v-model="patientDataFromSearch"
                  :items="getPatientsList"
                  :item-text="text"
                  clearable
                >
                </v-combobox>
              </v-col>

              <v-col cols="12" sm="3">
                <v-text-field
                  outlined
                  v-model="mobile"
                  placeholder="رقم الهاتف"
                  label="رقم الهاتف"
                  :rules="mobileRule"
                  maxlength="10"
                  minlength="10"
                  suffix="964+"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" v-if="isGovClinic">
                <v-text-field
                  clearable
                  v-model="Info.nationalId"
                  label="رقم الهوية الوطنية"
                  outlined
                  type="text"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  clearable
                  v-model="Info.dob"
                  label="تاريخ الميلاد"
                  outlined
                  type="date"
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="2">
                <v-select
                  v-model="Info.bloodType"
                  :items="bloodTypes"
                  item-text="val"
                  item-value="id"
                  label="فصيلة الدم"
                  placeholder="اختر فصيلة الدم"
                  required
                  :rules="requiredRule"
                  outlined
                ></v-select>
              </v-col>

              <v-col cols="12" sm="3">
                <v-select
                  v-model="Info.gender"
                  :items="genders"
                  item-text="val"
                  item-value="id"
                  label="الجنس"
                  placeholder="اختر النوع"
                  required
                  :rules="requiredRule"
                  outlined
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6">
                <v-select
                  v-model="Info.city"
                  :items="governorates"
                  item-text="val"
                  item-value="id"
                  label="المحافظة"
                  placeholder="اختر المحافظة"
                  required
                  :rules="requiredRule"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="Info.patientType"
                  :items="patientTypes"
                  label="حالة المراجع"
                  placeholder="اختر حالة المراجع"
                  outlined
                  item-text="text"
                  item-value="val"
                  ref="patientType"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" v-if="getRole == 'clinic_assistant'">
                <v-autocomplete
                  label="اسم الطبيب"
                  outlined
                  :rules="requiredRule"
                  v-model="Info.selectedDoctorId"
                  :items="getDoctorsList"
                  item-value="id"
                  :item-text="
                    (doctor) =>
                      doctor.user_info ? doctor.user_info.fullname : ''
                  "
                  clearable
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6" sm="3">
                <v-select
                  item-text="text"
                  item-value="val"
                  :items="paymentMethods"
                  label="طريقة الدفع"
                  v-model="Info.paymentMethod"
                  outlined
                  :rules="requiredRule"
                >
                </v-select>
              </v-col>

              <v-col cols="6" sm="3">
                <v-text-field
                  label="المبلغ"
                  type="number"
                  v-model="Info.amount"
                  append-icon="mdi-cash"
                  outlined
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field
                  cols="6"
                  sm="4"
                  v-if="
                    Info.paymentMethod == 'card' ||
                    Info.paymentMethod == 'insurance' ||
                    Info.paymentMethod == 'pos'
                  "
                  :label="getLabel()"
                  v-model="Info.insuranceCardNumber"
                  outlined
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-divider color="gray" height="2"></v-divider>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  ref="patientComment"
                  label="الشكوى الرئيسية"
                  outlined
                  v-model="Info.patientComment"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  v-model="Info.address"
                  placeholder="العنوان"
                  label="عنوان المراجع"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  v-model="Info.profession"
                  placeholder="المهنة"
                  label="مهنة المراجع"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  v-model="Info.drugAllergy"
                  placeholder="الحساسية للادوية"
                  label="الحساسية للادوية"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" v-if="!this.isGovClinic">
                <v-select
                  v-model="Info.patientStatus"
                  :items="patientStatusList"
                  label="وضع المراجع"
                  placeholder="اختر النوع"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3">
                <v-menu
                  ref="visitDateMenu"
                  v-model="visitDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="Info.visitDate"
                      label="تاريخ المراجعة"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="requiredRule"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="Info.visitDate"
                    :max="
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(0, 10)
                    "
                    min="1920-01-01"
                    @change="save2"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="3">
                <v-dialog
                  ref="visitTimeDialog"
                  v-model="visitTimeDialog"
                  :return-value.sync="Info.visitTime"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="Info.visitTime"
                      label="الوقت"
                      append-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      :rules="requiredRule"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="visitTimeDialog"
                    v-model="Info.visitTime"
                    full-width
                  >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.visitTimeDialog.save(Info.visitTime)"
                    >
                      اضافة
                    </v-btn>
                    <v-btn text color="red" @click="visitTimeDialog = false">
                      الغاء
                    </v-btn>

                    <v-spacer></v-spacer>
                  </v-time-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-form>
          <v-alert dense outlined type="error" v-if="errorAlrt">
            {{ errorAlrtText }}
          </v-alert>
          <v-alert dense outlined type="success" v-if="successAlrt">
            العملية تمت بنجاح
          </v-alert>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="indigo darken-1 white--text"
          :loading="loadingSpinner"
          :disabled="loadingSpinner"
          @click="addNewPatient"
        >
          اضافة
        </v-btn>
        <v-btn color="primary" text @click="closeDialog"> اغلاق </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar">
      الرجاء ملئ جميع الحقول
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          اغلاق
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";

  export default {
    props: ["AddPatientDialog"],
    watch: {
      "Info.patientType": function (newPatientType) {
        if (newPatientType === "chronic") {
          this.Info.amount = 3000;
        } else {
          // Set the default amount or handle other cases if needed
          this.Info.amount = null; // Change this to the default amount or null as needed
        }
      },
      "Info.paymentMethod": function (newPaymentMethod) {
        if (newPaymentMethod === "insurance" || newPaymentMethod === "cash") {
          this.Info.amount = null;
        }
        if (newPaymentMethod === "card") {
          this.Info.amount = 3000;
        }
      },
      patientDataFromSearch(newVal) {
        if (newVal != null && typeof newVal === "object") {
          console.log(newVal);
          this.Info.patientId = newVal.id;
          this.Info.fullname = newVal.fullname;
          this.Info.patientStatus = "عادي";
          this.mobile = newVal.mobile
            ? newVal.mobile.startsWith("964")
              ? newVal.mobile.substring(3, newVal.mobile.length)
              : newVal.mobile
            : null;
          this.Info.dob = newVal.date_of_birth;

          this.Info.bloodType = newVal.blood_type;
          this.Info.drugAllergy = newVal.drug_allergy;
          this.Info.address = newVal.address;
          this.Info.profession = newVal.profession;
          this.Info.nationalId = newVal.national_id;

          //   this.Info.patientType = newVal.patient_type;
          console.log("newVal.patient_type", newVal);
          this.governorates.forEach((gov) => {
            if (gov.val == newVal.city) this.Info.city = gov.id;
          });
          this.genders.forEach((gend) => {
            if (gend.eng == newVal.gender) this.Info.gender = gend.id;
          });
          if (this.isGovClinic) this.$refs.patientType.focus();
          else this.$refs.patientComment.focus();
        } else {
          this.Info.patientId = 0;
          this.Info.fullname = newVal;
          this.mobile = "";
          this.Info.dob = "";
          this.Info.bloodType = null;
          this.Info.drugAllergy = "";
          this.Info.gender = 0;
          this.Info.city = 0;
          this.Info.address = "";
          this.Info.profession = "";
          this.Info.patientComment = "";
          this.Info.patientStatus = "عادي";
          this.Info.nationalId = "";
          this.patientUuid = null;
          this.$refs.new_patient_form.resetValidation();
        }
      },
    },
    data() {
      return {
        patientUuid: null,
        patientDataFromSearch: null,
        patientNameForSearch: "",
        doctorNameForSearch: "",
        newpatient_valid: false,
        visitTimeDialog: false,
        genders: [
          { id: 1, val: "ذكر", eng: "male" },
          { id: 2, val: "انثى", eng: "female" },
        ],
        dobMenu: false,
        visitDateMenu: false,
        mobile: "",
        mobileRule: [
          (v) =>
            (this.isGovClinic && (v === "" || v == null)) || // Allow empty value if isGovClinic is true
            /^7[3-9][0-9]{8}$/.test(v) ||
            "اكتب رقم الهاتف بصورة صحيحة وبدون صفر في البداية",
        ],
        snackbar: false,
        requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
        loadingSpinner: false,
        errorAlrt: false,
        errorAlrtText: "حدث خطاء, فشل في الاضافة",
        successAlrt: false,
        Info: {
          patientId: 0,
          fullname: "",
          mobile: "",
          dob: "",
          age: null,
          bloodType: null,
          drugAllergy: "",
          gender: 0,
          city: 0,
          address: "",
          profession: "",
          visitDate: new Date().toISOString().slice(0, 10),
          visitTime: new Date().getHours() + ":" + new Date().getMinutes(),
          patientComment: "",
          patientStatus: "عادي",
          selectedDoctorId: null,
          patientType: "",
          paymentMethod: null,
          insuranceCardNumber: null,
          amount: null,
          nationalId: null,
        },
        patientTypes: [
          { text: "عامة", val: "normal" },
          { text: "مزمنة", val: "chronic" },
        ],
        patientStatusList: ["عادي", "متوسط", "طارئ"],
        bloodTypes: [
          "لااعلم",
          "A+",
          "A-",
          "B+",
          "B-",
          "O+",
          "O-",
          "AB+",
          "AB-",
        ],
        governorates: [
          { id: 1, val: "بغداد-الكرخ" },
          { id: 2, val: "بغداد-الرصافة" },
          { id: 3, val: "البصرة" },
          { id: 4, val: "نينوى" },
          { id: 5, val: "ذي قار" },
          { id: 6, val: "ميسان" },
          { id: 7, val: "المثنى" },
          { id: 8, val: "واسط" },
          { id: 9, val: "الديوانية" },
          { id: 10, val: "بابل" },
          { id: 11, val: "كربلاء المقدسة" },
          { id: 12, val: "النجف الاشرف" },
          { id: 13, val: "الانبار" },
          { id: 14, val: "صلاح الدين" },
          { id: 15, val: "ديالى" },
          { id: 16, val: "كركوك" },
          { id: 17, val: "اربيل" },
          { id: 18, val: "السليمانية" },
          { id: 19, val: "دهوك" },
        ],
        paymentMethods: [
          { val: "cash", text: "نقداً" },

          /*  { val: "card", text: "باص" },
          { val: "insurance", text: "ضمان صحي" }, */
          { val: "pos", text: "دفع الكتروني" },

          /*         { val: "installment", text: "تقسيط" },
           */
        ],
      };
    },
    computed: {
      ...mapGetters([
        "getPatientsList",
        "getRole",
        "getDoctorsList",
        "isGovClinic",
      ]),
    },
    created() {
      if (this.getRole == "clinic_assistant") this.fetchDoctorsList();
    },
    methods: {
      ...mapActions([
        "newPatientAndReservation",
        "fetchPatientsList",
        "fetchDoctorsList",
      ]),
      text: (item) =>
        `${item.fullname} - ${
          item.mobile
            ? item.mobile.startsWith("964")
              ? "0" + item.mobile.substring(3)
              : item.mobile
            : ""
        }`,
      getPatientDataByUuid() {
        if (this.patientUuid && this.patientUuid.length == 36) {
          this.fetchPatientsList(this.patientUuid).then(() => {
            if (this.getPatientsList)
              if (this.getPatientsList.length == 1) {
                this.patientDataFromSearch = this.getPatientsList[0];
              }
          });
        }
      },
      resetFields() {
        this.patientUuid = "";
        this.patientDataFromSearch = null;
      },
      getLabel() {
        if (
          this.Info.paymentMethod == "card" ||
          this.Info.paymentMethod == "insurance" ||
          this.Info.paymentMethod == "pos"
        ) {
          return this.Info.paymentMethod == "card"
            ? "رقم الباص"
            : this.Info.paymentMethod == "insurance"
            ? "رقم الضمان الاجتماعي"
            : "رقم الوصل";
        } else {
          return ""; // Set the default label or an empty string as needed
        }
      },
      searchForPatients() {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          if (this.patientNameForSearch != "")
            this.fetchPatientsList(this.patientNameForSearch).then(() => {
              if (this.getPatientsList)
                if (
                  this.getPatientsList.length === 1 &&
                  this.patientNameForSearch.length == 36
                ) {
                  this.patientDataFromSearch = this.getPatientsList[0];
                }
            });
        }, 800);
      },
      save(date) {
        this.$refs.dobMenu.save(date);
      },
      save2(date) {
        this.$refs.visitDateMenu.save(date);
      },
      addNewPatient() {
        if (!this.$refs.new_patient_form.validate()) return;
        this.loadingSpinner = true;
        if (this.mobile == "") this.Info.mobile = null;
        else this.Info.mobile = "964" + this.mobile;
        this.newPatientAndReservation(this.Info)
          .then(() => {
            this.successAlrt = true;
            this.errorAlrt = false;
            this.loadingSpinner = false;
            setTimeout(() => {
              this.successAlrt = false;
              this.$refs.new_patient_form.reset();
              this.Info.visitDate = new Date().toISOString().slice(0, 10);
              this.Info.visitTime =
                new Date().getHours() + ":" + new Date().getMinutes();
              this.Info.patientType = "normal";
              this.closeDialog();
            }, 2500);
          })
          .catch((err) => {
            if (err.response.data.errors) {
              if (
                err.response.data.errors.mobile[0] ==
                "The mobile has already been taken."
              )
                this.errorAlrtText = "رقم الهاتف الذي ادخلته مسجل لدينا مسبقا";
            } else this.errorAlrtText = "حدث خطاء, فشل في الاضافة";
            this.loadingSpinner = false;
            this.successAlrt = false;
            this.errorAlrt = true;
          })
          .finally(() => {
            this.loadingSpinner = false;
          });
      },
      handleClickOutSide(e) {
        //if (e.target.className == "v-overlay__scrim") this.closeDialog();
      },
      closeDialog() {
        this.successAlrt = false;
        this.$refs.new_patient_form.reset();
        this.Info.visitDate = new Date().toISOString().slice(0, 10);
        this.Info.visitTime =
          new Date().getHours() + ":" + new Date().getMinutes();
        this.$emit("addPatientDialogClose", false);
      },
    },
  };
</script>

<style scoped lang="scss">
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }

  @-moz-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
</style>
