//here are the routes of the vuex javascript
import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import patient_reservation from "./modules/patient_reservation";
import counter from "./modules/counter";
import user from "./modules/user";
import notification from "./modules/notification";
import offer from "./modules/offer";
import offer_subscription from "./modules/offer_subscription";
import clinic from "./modules/clinic";
import doctor from "./modules/doctor";
import specialization from "./modules/specialization";
import medical_advice from "./modules/medical_advice";
import credit_card from "./modules/credit_card";
import payment from "./modules/payment";
import chart from "./modules/chart";
import chronic_notebook from "./modules/chronic_notebook";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  actions: {},
  mutations: {},
  modules: {
    auth,
    patient_reservation,
    counter,
    user,
    notification,
    offer,
    offer_subscription,
    clinic,
    doctor,
    specialization,
    medical_advice,
    credit_card,
    payment,
    chronic_notebook,
    chart,
  },
});
